<template>
	<div>
		<div class="page-top-box">
		    <div class="page-title-box">
		        <div class="go-back">
		            <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
		        </div>
		        {{ date }}
		    </div>
		</div>
		<div class="page-content-box">			
			<div class="page-list-box">
			    <el-table
			        :data="dataList"			        
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
					header-row-class-name="page-list-table"
			        stripe
			    >
					<el-table-column prop="date" label="日期" width="120"></el-table-column>
					<el-table-column prop="activityId" label="演出ID" width="120"></el-table-column>
					<el-table-column prop="activityName" label="演出名称"></el-table-column>
					<el-table-column prop="formatAmount" label="销售额" width="120"></el-table-column>
					<el-table-column prop="totalNum" label="售票数"></el-table-column>
					<el-table-column prop="orderNum" label="订单数" width="120"></el-table-column>
					<el-table-column fixed="right" label="操作" width="200">
						<template slot-scope="scope">
						    <el-button
						        v-if="scope.row.activityId && $utils.have('/report/activitySalesCurvePage')"
						        type="text"
						        size="small"
						        @click="$router.push(`/manage/dashboard/hostsales/chart/${scope.row.activityId}/${scope.row.date}`)"
						        >查看完整销售曲线</el-button
						    >
						</template>
						
					</el-table-column>
				</el-table>
			</div>
			<div v-if="data" class="page-pagination-box">
			    <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="getHostdataList"
			        :current-page.sync="params.pageNo"
			        :page-sizes="[15, 30, 50]"
			        :page-size.sync="params.pageSize"
			        layout="sizes, prev, pager, next"
			        :total="data.totalCount || 0">
			    </el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
export default {   
	props: {
	    date: {
	        type: [Number, String],
	        default: null,
	    },
	},    
    data() {
        return {            
			listLoading: false,           
			params:{
			    pageNo:parseInt(this.$route.query.pageNo) || 1,
			    pageSize: parseInt(this.$route.query.pageSize) || 15,			    
			},
			data:null,
			dataList: null,
			redirect: this.$route.query.redirect || '/manage/dashboard/hostsales',
			
        };
    },    
	mounted(){
	   this.getHostdataList()
	},
	
    methods:{		
		//指定日期主办活动销售
		getHostdataList(){
			
			this.$request({
			    url: "/report/activityDateSales",
			    method: "POST",				
				data: {...this.params,date: this.date},
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
					   this.data = result
			           this.dataList = result.result
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        
			    });
		},
		//选择pageSize
		handleSizeChange(e) {
		    this.params.pageNo = 1;
		    this.params.pageSize = e;
		    this.getHostdataList();
		},
		
		//返回
		goBack() {
		    this.$router.push({
		        path: this.redirect,
		    });
		},
    }
};
</script>

<style lang="scss" scoped>
.page-top-box{
	
}
.page-tab-box{
    margin: -20px -20px 20px -20px;
    padding: 0 20px;
    border-bottom: 1px #E4E7ED solid;
    ::v-deep .el-tabs__header{
        border-bottom: 0;
        margin: 0;
    }
}
.data-group{
    > ::v-deep .el-card__header{
        font-size: 16px;
        line-height: 22px;
        a{
            font-size: 14px;
        }
    }
}
.markRed{
	padding:40px 0 0 0;
	color: #f00;
}
</style>